import React, { useState, useEffect } from 'react'
import {
  Text,
  Heading,
  Container,
  Stack,
  Divider,
  ListItem,
  OrderedList,
  // UnorderedList,
  Button,
} from '@chakra-ui/react'
import { graphql } from 'gatsby'
import BaseLayout from '../../../layout/base'
import {
  Heading1,
  Heading2,
  Heading3,
  // Heading4,
  // Heading5,
  // Heading6,
  Paragraph,
} from '../../../components/ContentWithStyles/TransformToChakra'
import SEO from '../../../components/SEO/PageSEO'

const Page = ({ location, data }) => {
  const [word, setWord] = useState('Palabra')
  const selectRandomItem = someList =>
    someList[Math.floor(Math.random() * someList.length)]
  useEffect(() => {
    setWord(
      selectRandomItem(
        data.wordsJson.english.nouns
          .concat(data.wordsJson.english.adjectives)
          .concat(data.wordsJson.english.verbs)
      )
    )
  }, [
    data.wordsJson.english.nouns,
    data.wordsJson.english.adjectives,
    data.wordsJson.english.verbs,
  ])

  return (
    <BaseLayout>
      <SEO
        title="Generador de adjetivos aleatorios"
        titleSeo="Generador de adjetivos aleatorios"
        description="Herramienta para generar adjetivos al azar."
        image={data.file.childImageSharp.gatsbyImageData.images.fallback.src}
        // lang="es"
        datePublished="2021-05-11T12:29:33-03:00"
        dateModified="2021-05-11T12:29:33-03:00"
        breadcrumbs={[
          { name: 'Palabras', url: '/palabras/' },
          { name: 'Inglés', url: '/ingles/' },
        ]}
        permalink={location.pathname}
        // breadcrumbList={[]}
        // noindex={false}
      />
      <Container maxW="5xl">
        <Stack
          textAlign="center"
          align="center"
          spacing={{ base: 8, md: 10 }}
          py={{ base: 20, md: 28 }}
        >
          <Heading
            fontWeight={600}
            fontSize={{ base: '3xl', sm: '4xl', md: '6xl' }}
            lineHeight="110%"
            as="span"
          >
            <Text as="span" color="orange.400">
              {word}
            </Text>
          </Heading>
          <Heading as="h2" color="gray.500" size="md" fontWeight={500}>
            Generador de palabras en inglés aleatorias
          </Heading>

          <Stack spacing={2} direction="row">
            <Button
              rounded="full"
              px={6}
              colorScheme="orange"
              bg="orange.400"
              _hover={{ bg: 'orange.500' }}
              onClick={() =>
                setWord(
                  selectRandomItem(
                    data.wordsJson.english.nouns
                      .concat(data.wordsJson.english.adjectives)
                      .concat(data.wordsJson.english.verbs)
                  )
                )
              }
            >
              Generar
            </Button>
          </Stack>
        </Stack>
      </Container>
      <Divider />
      <Container maxW="5xl" my={10}>
        <Heading1>Generador de palabras en ingl&eacute;s aleatorias</Heading1>
        <Paragraph>
          As&iacute; como en este sitio te podr&aacute;s encontrar generadores
          de palabras en espa&ntilde;ol aleatorias (/link/), sustantivos al azar
          (/link/), adjetivos al azar (/link/) y mucho m&aacute;s,
          tambi&eacute;n est&aacute; disponible este maravilloso generador de
          palabras en ingl&eacute;s al azar, que te permitir&aacute; obtener
          palabras del idioma ingl&eacute;s de forma aleatoria para cualquier
          uso que tengas en mente.
        </Paragraph>
        <Paragraph>
          Generar una nueva palabras en ingl&eacute;s al azar es tan simple como
          tocar el bot&oacute;n (ya sea en un m&oacute;vil o en un ordenador) y
          en un instante tendr&aacute;s tu nueva palabras en pantalla, pudiendo
          esta ser un sustantivo, un adjetivo, un verbo, y pertenecer a
          cualquier contexto: ser una herramienta f&iacute;sica del mundo real,
          una criatura fant&aacute;stica de alg&uacute;n relato popular,
          palabras de uso coloquial, t&eacute;rminos formales, colores, objetos,
          animales, sentimientos, cualidades, acciones cotidianas, tipos de
          cosas o personas, etc.
        </Paragraph>
        <Paragraph>
          Las palabras que podr&aacute;s obtener ser&aacute;s comunes en el
          idioma ingl&eacute;s y no tendr&aacute;s que preocuparte por obtener
          t&eacute;rminos muy oscuros o de casi nulo uso, dado que este
          generador de palabras en ingl&eacute;s cuenta en su gran
          mayor&iacute;a con palabras populares que cualquier persona con un
          nivel de ingl&eacute;s b&aacute;sico podr&aacute; entender, aunque
          tambi&eacute;n habr&aacute; otras un poco m&aacute;s complejas, pero
          igualmente frecuentes y comunes.
        </Paragraph>
        <Paragraph>
          La lista de palabras en ingl&eacute;s disponible en el generador
          est&aacute; filtrada y controlada de forma manual para evitar
          t&eacute;rminos que no sean apropiados para todos los contextos y
          remover palabras demasiado extra&ntilde;as o ya muy antiguas y en
          desuso.
        </Paragraph>
        <Heading2>
          Generar palabras en ingl&eacute;s &uacute;nicas y originales al azar
        </Heading2>
        <Paragraph>
          A pesar de que se ha mencionado en p&aacute;rrafos anteriores el hecho
          de que son palabras comunes y frecuentes dentro del lenguaje cotidiano
          del ingl&eacute;s, eso no quita que la lista sea extensa y est&eacute;
          repleta de opciones muy variadas e interesantes que te
          permitir&aacute;n utilizar este generador de palabras en ingl&eacute;s
          tantas veces como quieras sin estarte topando una y otra vez con las
          mismas palabras, ni encontrarte &uacute;nicamente dentro de un
          contexto (por ejemplo, solo generar animales o colores), sino que
          obtendr&aacute;s t&eacute;rminos en ingl&eacute;s muy variados incluso
          luego de haber generado cientos m&aacute;s previamente.
        </Paragraph>
        <Paragraph>
          &nbsp;Al estar manualmente controlada la lista que toma como fuente
          este generador aleatorio, no tendr&aacute;s que preocuparte por
          encontrar t&eacute;rminos que no sean adecuados, no tengan sentido o
          est&eacute;n mal escritos. Puedes utilizar esta herramienta online con
          confianza gracias a los filtros de calidad puestos en la etapa de
          producci&oacute;n.
        </Paragraph>
        <Heading3>
          Generaci&oacute;n de palabras verdaderamente aleatoria
        </Heading3>
        <Paragraph>
          El algoritmo de generaci&oacute;n se centra en ser verdaderamente
          aleatorio y evitar sesgos o patrones, por lo que no te
          encontrar&aacute;s con palabras que sigan un orden determinado o
          alguna proporci&oacute;n fija, adem&aacute;s de que no tendr&aacute;s
          que limitarte a las palabras que conoces o que te son m&aacute;s
          familiares, ya que las palabras aqu&iacute; generadas provienen de
          muchos contextos distintos.
        </Paragraph>
        <Paragraph>
          El proceso en s&iacute; mismo resulta muy sencillo: tomar una lista de
          elementos y seleccionar uno al azar, lo que se repetir&aacute; tantas
          veces como el usuario desee. Entonces, &iquest;por qu&eacute; utilizar
          una herramienta en lugar de hacerlo de forma manual? Principalmente
          existen dos motivos por los que uno podr&iacute;a preferir este
          generador online a una selecci&oacute;n al azar m&aacute;s manual:
        </Paragraph>
        <OrderedList>
          <ListItem>
            La lista predefinida de palabras es extensa y variada.
          </ListItem>
          <ListItem>
            El algoritmo es verdaderamente aleatorio y evitar sesgos o patrones.
          </ListItem>
        </OrderedList>
        <Paragraph>
          Para entrar en m&aacute;s detalles, respecto al punto uno, el contar
          con una lista ya definida y controlada de miles de elementos (palabras
          en ingl&eacute;s, en este caso), el usuario se libra de tener que
          hacer esta recopilaci&oacute;n de forma manual. Esto podr&iacute;a
          parecer una tarea banal y sin muchas implicaciones, pero lograr crear
          una lista con miles de palabras variadas y &uacute;nicas puede
          resultar desafiante si se quiere hacer de forma manual, o simplemente
          una gran p&eacute;rdida de tiempo, &iquest;Por qu&eacute; hacerlo si
          no resulta necesario? &iquest;Por qu&eacute; intentar hacerlo de forma
          manual cuando ya existen listas creadas y filtradas disponibles para
          su uso inmediato?
        </Paragraph>
        <Paragraph>
          Y con respecto al segundo punto, podr&iacute;a parecer que hacer una
          selecci&oacute;n al azar entre los elementos de una lista es una tarea
          trivial y sin mucha profundidad, pero la realidad es que el cerebro
          humano posee muchos mecanismos para obtener diferentes beneficios,
          como por ejemplo reducir el consumo de energ&iacute;a y estr&eacute;s
          durante la toma de decisiones.
        </Paragraph>
        <Paragraph>
          Es f&aacute;cil caer en sesgos y preferencias personales cuando uno
          intenta escoger elementos de una lista al azar, ya sea seleccionando
          aquellos que nos gusten m&aacute;s, los que m&aacute;s conocemos, lo
          que nos dar&aacute;n una ventaja en su uso, o de forma alternativa,
          siguiendo alg&uacute;n orden en particular: n&uacute;meros impares o
          pares, n&uacute;meros primeros, n&uacute;meros que sean importantes a
          nivel personal, etc.
        </Paragraph>
        <Paragraph>
          As&iacute; es como el tener un generador de palabras en ingl&eacute;s
          al azar resulta &uacute;til y pr&aacute;ctico gracias a la lista de
          palabras ya recopilada y controlada, y al algoritmo de
          selecci&oacute;n al azar.
        </Paragraph>
        <Heading3>
          Posibles usos para el generador de palabras en ingl&eacute;s al azar
        </Heading3>
        <Paragraph>
          Como nota adicional, podr&iacute;amos plantearnos qu&eacute; usos
          tiene una herramienta como esta, de naturaleza tan simple y limitada
          como lo es un generador de palabras al azar en ingl&eacute;s.
        </Paragraph>
        <Paragraph>
          En primer lugar, si un usuario se encuentra ya en esta p&aacute;gina y
          est&aacute; leyendo este texto, posiblemente haya llegado al generador
          con un uso en mente, dado que un generador de palabras aleatorias no
          es algo con lo que uno se topa sin buscarlo, por lo general. Es tan
          espec&iacute;fico y claro en su prop&oacute;sito y funcionalidad, que
          quien acabe en esta p&aacute;gina posiblemente ya sabr&aacute; para
          qu&eacute; quiere hacer uso del mismo.
        </Paragraph>
        <Paragraph>
          A pesar de eso, en un segundo lugar, podemos igualmente explorar
          qu&eacute; usos tiene una herramienta de generaci&oacute;n aleatoria
          de palabras en ingl&eacute;s, porque resulta cuanto menos curioso que
          existe una utilidad tan espec&iacute;fica y enfocada tan claramente a
          una tarea trivial como lo es la selecci&oacute;n de palabras de forma
          aleatoria.
        </Paragraph>
        <Paragraph>
          Para quienes est&eacute;n aprendiendo ingl&eacute;s, obtener palabras
          al azar puede resultar &uacute;til para ampliar el vocabulario gracias
          a la exposici&oacute;n a palabras nuevas y diferentes, o para
          practicar la pronunciaci&oacute;n, al tener que centrarse
          &uacute;nicamente en una palabra a la vez y no saber qu&eacute; espera
          a continuaci&oacute;n.
        </Paragraph>
        <Paragraph>
          M&aacute;s all&aacute; de aprender y/o mejorar el ingl&eacute;s, una
          persona podr&iacute;a llegar aqu&iacute; buscando palabras en
          ingl&eacute;s para fines did&aacute;cticos como lo son los juegos de
          palabras, competencias de deletreo, actividades que requieran de un
          elemento aleatorio o sorpresa, o simplemente est&eacute; buscando
          alg&uacute;n punto de partida con el cual comenzar a construir sus
          ideas.
        </Paragraph>
      </Container>
    </BaseLayout>
  )
}

export default Page

export const pageQuery = graphql`
  query {
    wordsJson {
      english {
        nouns
        adjectives
        verbs
      }
    }
    file(relativePath: { regex: "/extreme-5497194_1920-photopea.png/" }) {
      childImageSharp {
        gatsbyImageData(quality: 40, width: 800)
      }
    }
  }
`
